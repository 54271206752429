<template>
  <section>
      <div class="content-header">
        <h2>Five ways to discuss resilience with students</h2>
      </div>
      <div class="content-wrapper">
        <p>Effective teachers can foster academic tenacity in their students in many ways. You can:</p>
        <ul>
          <li><strong class="bold">Reinforce the message that your students belong in school:</strong> When students believe that they have the potential to grow and excel, and that their teachers care, they are more likely to be tenacious in the classroom</li>
          <li><strong class="bold">Challenge your students with high performance standards:</strong> Studies show that students work harder for teachers who expect more of them</li>
          <li><strong class="bold">Provide high-quality feedback:</strong> Substantive feedback shows students that you are committed to learning and believe in their capacity for growth</li>
          <li><strong class="bold">Help your students with goal-setting strategies:</strong> Encourage them to write specific, concrete goals in a planning book, or by breaking down long-term goals into concrete, short-term goals</li>
          <li><strong class="bold">Create small groups or “communities of learners”:</strong> Working in small groups promotes social learning, helps students see that others may be having similar difficulties with coursework, and creates communal goals and fosters a social identity around coursework </li>
        </ul>
        <p class="lm "> Learn more</p>
        <p>Pick up some tips and techniques for fostering academic tenacity in your students in this online book, entitled <a href="https://eric.ed.gov/?id=ED576649" target="_blank">Academic Tenacity: Mindsets and Skills that Promote Long-term Learning</a> (Bill and Melinda Gates Foundation ERIC).</p>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '02',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
